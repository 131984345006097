<script>
import { ValidationObserver } from 'vee-validate'
import { useRouter } from 'vue-router/composables'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import { useBackendValidation } from '/~/composables/backend-validation'
import { useForm } from '/~/composables/base/use-form'
import { useCms } from '/~/composables/cms/use-cms'
import { useProvider } from '/~/composables/provider'
import { useUI } from '/~/composables/ui'
import { useUser } from '/~/composables/user'

export default {
  name: 'profile-change-password-v2',
  components: {
    BaseInput,
    BaseButton,
    BaseAsidePage,
    ValidationObserver,
  },
  setup() {
    const { isDarkThemeForEwallet } = useCms()
    const { updateUserPassword } = useUser()
    const { isRequiredAsteriskVisible } = useUI()
    const { backendErrors, processBackendErrors } = useBackendValidation()
    const { validationObserverRef } = useForm()
    const router = useRouter()
    const { isPasswordEditable } = useProvider()

    if (!isPasswordEditable.value) {
      router.push({ hash: '' })
    }

    return {
      updateUserPassword,
      isDarkThemeForEwallet,
      isRequiredAsteriskVisible,
      backendErrors,
      processBackendErrors,
      validationObserverRef,
    }
  },
  data() {
    return {
      form: {
        currentPassword: '',
        newPassword: '',
        newPasswordConfirmation: '',
      },
      submitting: false,
    }
  },
  methods: {
    async onSubmitForm() {
      this.submitting = true
      this.backendErrors = {}

      try {
        await this.updateUserPassword({
          ...this.form,
          confirmPassword: this.form.newPassword,
        })
        this.$router.back()
        this.form.currentPassword = ''
        this.form.newPassword = ''
        this.form.newPasswordConfirmation = ''
        this.validationObserverRef?.reset()
      } catch (errors) {
        this.processBackendErrors({ errors })
      } finally {
        this.submitting = false
      }
    },
  },
}
</script>

<template>
  <base-aside-page title="Change Password" no-padding>
    <validation-observer
      v-slot="{ handleSubmit }"
      ref="validationObserverRef"
      slim
    >
      <form
        class="relative flex h-full flex-col"
        @submit.prevent="handleSubmit(onSubmitForm)"
      >
        <div class="relative flex-grow overflow-y-auto px-5">
          <base-input
            v-model="form.currentPassword"
            :validation="{
              rules: 'required',
              name: 'Current Password',
              vid: 'current_password',
            }"
            :error="backendErrors.currentPassword"
            :disabled="submitting"
            label="Current password"
            required
            type="password"
            :required-asterisk="isRequiredAsteriskVisible"
            entry-class="h-12 rounded-md"
            :style="{
              '--color-text': isDarkThemeForEwallet ? '#FFFFFF' : null,
            }"
          />
          <base-input
            ref="new_password"
            v-model="form.newPassword"
            :validation="{
              rules: 'required|password',
              name: 'New Password',
              vid: 'new_password',
            }"
            :disabled="submitting"
            label="New password"
            required
            name="new_password"
            type="password"
            :required-asterisk="isRequiredAsteriskVisible"
            entry-class="h-12 rounded-md"
            :style="{
              '--color-text': isDarkThemeForEwallet ? '#FFFFFF' : null,
            }"
          />
          <base-input
            ref="new_password_confirmation"
            v-model="form.newPasswordConfirmation"
            :validation="{
              rules: 'required|confirmed:@new_password,Password',
              name: 'Confirm New Password',
              vid: 'new_password_confirmation',
            }"
            :disabled="submitting"
            label="Confirm new password"
            required
            name="new_password_confirmation"
            type="password"
            :required-asterisk="isRequiredAsteriskVisible"
            entry-class="h-12 rounded-md"
            :style="{
              '--color-text': isDarkThemeForEwallet ? '#FFFFFF' : null,
            }"
          />
        </div>
        <div class="flex-shrink-0 p-5">
          <base-button type="submit" :disabled="submitting" full-width>
            {{ submitting ? 'Saving...' : 'Save' }}
          </base-button>
        </div>
      </form>
    </validation-observer>
  </base-aside-page>
</template>
